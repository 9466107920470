import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const AboutUs = lazy(() => import("./about/about"));
const OurWork = lazy(() => import("./ourwork/ourwork"));
const Donors = lazy(() => import("./donors/donors"));
const Error404 = lazy(() => import("./error/404"));
const Login = lazy(() => import("./login/login"));
const Donate = lazy(() => import("./donate/donate"));
const Team = lazy(() => import("./team/team"));
const ContactUs = lazy(() => import("./contactus/contact"));
const PrisonVoicePage = lazy(() => import("./prisonvoices/prisonvoices"));
const FAQs = lazy(() => import("./faqs/faqs"));
const Careers = lazy(() => import("./career/career"));
const Resources = lazy(() => import("./resources/resources"));
const PAARMap = lazy(() => lazyRetry(() => import("./mapping/google")));
const Report = lazy(() => import("./report/report"));
const Home = lazy(() => import("./home/home"));
const Article = lazy(() => import("./article/article"));
const TermsAndConditions = lazy(() => import("./tc_and_pp/tc_and_pp"));
const Privacy = lazy(() => import("./tc_and_pp/policy"));
const PdfPage = lazy(() => import("./rti/pdfPage"));

const client = new ApolloClient({
  uri: "https://cms.paar.org.in/graphql",
  cache: new InMemoryCache(),
});

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default function Main() {
  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/who-we-are" element={<AboutUs />} />
          <Route path="/our-work" element={<OurWork />} />
          <Route path="/error404" element={<Error404 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/prisonvoices" element={<PrisonVoicePage />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/paar-media" element={<Resources />} />
          <Route path="/map" element={<PAARMap />} />
          <Route path={`/pdf`} element={<PdfPage />} />
          <Route path="/our-supporters" element={<Donors />} />
          <Route path={`/report`} element={<Report />} />
          <Route path="/article" element={<Article />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Suspense>
    </ApolloProvider>
  );
}

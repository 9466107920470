import Main from "./components/main.js";
import ReactGA from "react-ga";
ReactGA.initialize("UA-212265006-1", { standardImplementation: true });
import './_App.scss';
function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  window.addEventListener("error", (e) => {
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
    }
  });
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
